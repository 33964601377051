import { forwardRef, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { isNullOrUndefined } from "../../utils/helper";

const Input = forwardRef((props, ref) => {
  const {
    id,
    value,
    width,
    label,
    className,
    disabled,
    disabledV2,
    maxLength,
    focus = false,
    type = "text",
    onChange = () => {},
    onBlur = () => {},
  } = props;

  const [isFocus, setIsFocus] = useState(focus);
  const [internalValue, setInternalValue] = useState(value || "");

  useEffect(() => {
    if (!isNullOrUndefined(value)) {
      setInternalValue(value);
    }
    if (document.getElementById(id)?.value !== "") {
      setIsFocus(true);
    }
  }, [value, id]);

  const handleFocus = () => {
    if (disabledV2) return;
    setIsFocus(true);
  };

  const handleBlur = (e) => {
    if (disabledV2) return;
    if (document.getElementById(id)?.value === "") {
      setIsFocus(false);
    }
    onBlur(e);
  };

  const handleChange = (e) => {
    if (disabledV2) return;
    let inputValue = e.target.value;

    if (type === "number") {
      inputValue = inputValue.replace(/\D/g, "");
    }

    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength);
    }

    setInternalValue(inputValue);
    onChange(e);
  };

  return (
    <div className={twMerge(["relative", width])}>
      {label && (
        <label
          className={twMerge([
            "pointer-events-none absolute left-0 top-0 z-10 translate-x-4 translate-y-4 bg-white text-sm text-on-surface-black-64 transition-all duration-300",
            disabled && "text-stroke-dark-12 opacity-30",
            (isFocus || internalValue) &&
              "translate-y-1 bg-transparent text-xs",
          ])}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <input
        {...props}
        ref={ref}
        id={id}
        type={type}
        value={internalValue}
        className={twMerge([
          "spin-button-none w-full rounded-lg border border-stroke-dark-12 px-4 py-4 text-sm font-medium tracking-wide text-primary shadow-sm outline-none transition duration-200 ease-in-out",
          "placeholder:text-on-surface-black-24",
          "active:border-on-surface-dark-64",
          "focus:border-stroke-dark-12 focus:border-opacity-40",
          disabledV2
            ? " cursor-not-allowed text-primary"
            : "disabled:cursor-not-allowed disabled:opacity-30",
          label && "pb-3 pt-5",
          className,
        ])}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={disabledV2 ? false : disabled}
      />
    </div>
  );
});

export default Input;
