import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

const RadioButton = forwardRef((props, ref) => {
  const {
    label,
    checked = false,
    id = checked,
    onChange,
    className, // Custom className passed from the parent
  } = props;

  return (
    <div
      className={twMerge(
        "ml-8 flex items-center text-primary first:ml-0",
        className,
      )}
    >
      <input
        {...props}
        ref={ref}
        checked={checked}
        onChange={onChange}
        type="radio"
        className="h-4 w-4 cursor-pointer accent-primary"
        id={`radio-button-${id}`}
      />
      <label
        className="w-full pl-4 hover:cursor-pointer"
        htmlFor={`radio-button-${id}`}
      >
        {label}
      </label>
    </div>
  );
});

export default RadioButton;
